.csc-peer-app-g-w {
  width: 1280px !important;
  margin: auto;
  padding: 0px;
  height: 865px !important;
  color: black !important;
}

.csc-main-headings {
  font-size: 22px;
  font-family: Arial, Helvetica, sans-serif;
  color: black !important;
}

.csc-graph-content {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: black !important;
}

.csc-sub-headings {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: black !important;
}