.bx--data-table-v2 thead th .text-center {
  display: block;
}

.bx--toolbar-search-container-active {
width: 100% !important;
}

.bx--batch-actions--active {
z-index: 1111;
}

.text-center .bx--table-header-label {
text-align: center !important;
}

tbody.candidate-background-color tr:nth-child(2n), tbody.candidate-background-color tr:nth-child(2n) td {
  background-color: #726c6c0d;
  vertical-align: top !important;
}

tbody.candidate-background-color tr:nth-child(2n - 1), tbody.candidate-background-color tr:nth-child(2n - 1) td {
  background-color: white;
  vertical-align: top !important;
}

.css-sm-tile-b {
  border: 1px solid #bbbfc3 !important;
}

.csc-table-search-expandable {
  border: 1.5px solid gray !important;
}