.report-graph-height {
  height: 1235px;
  background-color: white;
}

.pslp-neo-outcome-h {
  height: 220px;
  background-color: white;
}

.pslc-neo-width {
  width: 1280px !important;
  margin: auto;
  padding: 0px;
}

.report-print-view {
  width: 1880px !important;
  margin: auto;
  padding: 0px;
}

.invalid-msg {
  max-height: 12.5rem;
  display: block;
  color: #da1e28;
}

.pslp-cpi-outcome-h {
  height: 235px;
  background-color: white;
}

.pslp-cpi-table-width {
  width: 895px !important;
}

.table-width {
  width: 595px;
}

.header-width {
  max-width: 60px;
  min-width: 60px;
}

.circle-fill-points {
  border: 2px solid #1f4e79;
  min-width: 10px;
  min-height: 10px;
  max-width: 10px;
  max-height: 10px;
  border-radius: 50%;
  background-color: #1f4e79;
}

.diamond-fill-points {
  border: 2px solid #1f4e79;
  min-width: 10px;
  min-height: 10px;
  max-width:10px;
  max-height: 10px;
  background-color: #1f4e79;
  transform: rotate(45deg);
}



.css-outcome-color-L, .css-outcome-color-VL {
  color: #de53ff;
  font-size: 24px !important;
  font-family: Arial, Helvetica, sans-serif;
}

.css-outcome-color-ML, .css-outcome-color-M, .css-outcome-color-MH {
  color: #70ad47;
  font-size: 24px !important;
  font-family: Arial, Helvetica, sans-serif;

}

.css-outcome-color-H, .css-outcome-color-VH {
  color: #6a9cc9;
  font-size: 24px !important;
  font-family: Arial, Helvetica, sans-serif;
}

.cul-f-sz {
  font-size: 35px !important;
}

.personality-profile-table {
  font-weight: normal;
  font-size: 16px !important;
}

.css-outcome-body {
  border: 3px solid black;
}

.css-outcome-body .outcome-table-solid-border {
  border-left: 1px solid black;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.outcome-b-r {
  border-right: 2px solid black;
}

.css-outcome-body td {
  vertical-align: middle;
}

.css-outcome-body .otcome-border-btm {
  border-bottom: 2px solid white;
}

.css-outcome-body .otcome-border-btm-b {
  border-bottom: 2px solid black;
}

.outcome-table-name {
  font-weight: bold;
}

.css-outcome-body .outcome-table-dashed-border {
  border-left: 1px dashed black;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.pslp-graph-height {
  height: 1400px;
  background-color: white;
}

.css-outcome-body .css-pslp-desc-align {
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.css-desc-fsz {
  font-size: 18px !important;
}

.css-pslp-metric-w {
  width: 140px;
}

.css-pslp-desc-w {
  width: 310px;
  height: 25px;
}

.css-outcome-head .css-rating-w {
  min-width: 20px;
  max-width: 20px;
}

.pslp-tb-w {
  width: 560px;
}

.css-cilt-report-body .css-cultural-desc-w {
  min-width: 100px;
  max-width: 100px;
}

.css-cilt-report-body .css-cult-rep-solid-b {
  border-left: 1px solid black;
  text-align: center;
}

.css-cilt-report-body .css-cult-rep-dashed-b {
  border-left: 1px dashed black;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top !important;
}

.css-cult-rep-head-b {
  border-bottom: 1px solid black;
}

.css-cultural-rating-w {
  min-width: 25px;
  max-width: 25px;
  vertical-align: top;
}

.css-cult-rep-metric {
  font-weight: bold;
}

.css-cult-rep-band-circle-o {
  border: 1px solid;
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.css-cult-rep-band-MH, .css-cult-rep-band-VH, .css-cult-rep-band-ML, .css-cult-rep-band-VL {
  font-weight: bold;
  top: 11.5px;
  left: 5.5px;
  position: relative;
}

.css-cult-rep-band-H, .css-cult-rep-band-L {
  font-weight: bold;
  top: 10.5px;
  left: 18px;
  position: relative;
}

.css-cult-rep-band-M {
  font-weight: bold;
  top: 10.5px;
  left: 14px;
  position: relative;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.css-cul-svg-sw {
  stroke-width: 15px;
}

.css-cult-graph-h {
  height: 710px;
  background-color: white;
}



.css-score-circle {
  border: 1px solid;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.cult-rep-fsz {
  font-size: 20px !important;
  font-weight: normal !important;
  color: #7d7d7d !important;
}

.cult-rep-fsz-desc {
  font-size: 15px !important;
}

.css-color--factor--neuro {
  background-color: #ccccff;
}

.css-color--factor--consc {
  background-color: #e2efda;
}

.css-color--factor--openness {
  background-color: #ddecf8;
}

.css-color--factor--aggree {
  background-color: #fce4d6;
}

.heading-percentile {
  background-color: #fff2cc;
}

.pslp-count-w {
  width: 100px;
}

.metric-trs {
  transform: rotate(270deg);

}

.metric-pslp-cpi-f-sz {
  font-size: 18px;
  font-weight: bold;
}

.table-layout-fixed {
  table-layout: fixed;
}

.diamond {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  z-index: 100000;
}

.diamond-pslp-nep {
  width: 6px;
  height: 6px;
  transform: rotate(45deg);
}

.css-t-score-color-VL, .css-t-score-color-L {
  background-color: #de53ff;
}


.css-t-score-color-MH, .css-t-score-color-ML, .css-t-score-color-M {
  background-color: #70ad47;
}

.css-t-score-color-VH, .css-t-score-color-H {
  background-color: #6a9cc9;
}

.css-psp-neo-th-trait-w {
  width: 200px;
}

.css-psp-neo-th-trait-w-sub {
  width: 260px;
}

.css-psp-neo-th-score-w {
  width: 40px;
  border-bottom: 2px solid black;
}

.css-pslp-neo-th-main-b {
  border-left: 2px solid black;
  width: 40px;
  height: 10px;
}

.css-pslp-neo-th-sub-b-dash {
  border-left: 0.8px dashed lightgray;
  height: 5px;
}

.css-pslp-neo-th-sub-b {
  border-left: 1px solid black;
  width: 40px;
}

.css-psp-neo-td-score-border-s {
  border-left: 2px solid black;
}

.css-psp-neo-td-score-border-sub-s {
  border-left: 1px solid black;
}

.css-psp-neo-td-score-border-d {
  border-left: 0.8px dashed lightgray;
}

.css-pslp-neo-td-b {
  border-bottom: 0.8px solid lightgray;
}

.css-pslp-nro-row-h {
  height: 40px;
}

.css-pslp-neo-th-f-sz {
  font-size: 22px !important;
  font-weight: normal;
}

.css-pslp-neo-trait-f-sz {
  font-size: 20px !important;
  font-weight: normal;
}

.css-pslp-neo-trait-f-sz-sub {
  font-size: 15px !important;
  font-weight: normal;
  color: gray;
}

.css-pos-abs-point {
  position: absolute;
  bottom: -7px;
  left: 27px;
}

.css-cult-rep-dash-b {
  border-left: 1px dashed black;
}

.css-top {
  top: 20px;
}

.css-bot {
  bottom: 47px;
}

.css-left {
  left: 25px;
}

.css-right {
  right: 25px;
}

.css-psl-neo-l {
  left: 60px !important;
  z-index: 100;
  top: -6px;
}

.css-pslp-noe-plot-r {
  left: 42px !important;
}

.css-pslp-noe-plot-l {
  left: 10px !important;
}

.css-pslp-noe-plot-l-one {
  left: 13px !important;
}

.css-pslp-noe-plot-l-20 {
  left: -5px !important;
}

.css-cult-rep-point {
  left: 50px
}

.css-cult-rep-point-one {
  left: 40px
}

.css-cult-rep-point-two {
  left: 25px
}

.css-cult-rep-point-4 {
  left: 39px;
}

.css-cult-rep-point-5 {
  left: 54px;
}

.css-cult-rep-r {
  right: 40px;
}

.css-cult-rep-t-score-r {
  right: 15px;
}

.css-behv-w {
  width: 560px;
}

.css-supp-behv-fsz {
  font-size: 19px;
}

.css-behv-heading {
  font-size: 26px !important;
    text-decoration: underline;
}

.css-tscore-m-l {
  z-index: 100000000;
  margin-left: 29px;
}

.css-tscore-m-l-1 {
  z-index: 100000000;
  margin-left: 15px;
}

.css-tscore-m-l-2 {
  z-index: 100000000;
  margin-left: -13px;
}

.css-tscore-m-l-3 {
  z-index: 100000000;
  margin-left: -20px;
}

.css-tscore-m-l-4 {
  z-index: 100000000;
  margin-left: 49px;
}

.css-tscore-m-r {
  z-index: 100000000;
  margin-right: 29px;
}

.css-tscore-m-r-1 {
  z-index: 100000000;
  margin-right: 6px;
}

.css-tscore-m-r-2 {
  z-index: 100000000;
  margin-right: 9px;
}

.css-tscore-m-r-3 {
  z-index: 100000000;
  margin-left: 29px;
}

.css-tscore-m-r-4 {
  z-index: 100000000;
  margin-left: 38px;
}

.css-tscore-l {
  left: 9px;
}

.css-left-20-score {
  z-index: 100000000;
  right: 49px;
}

.css-right-20-score {
  z-index: 100000000;
  margin-right: 48px;
}

.css-right-80-score {
  z-index: 100000000;
  margin-left: 50px;
}
