.css-web-height {
  height: calc(100vh - 27px);
  width: 100%;
  margin: 0px;
}

.left-bg-color {
  background-color: white;
}

.text-color-white {
  color: white !important;
  font-weight: bold;
}

.input-wid {
  min-width: 325px;
}