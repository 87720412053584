.csc-h-100 {
  height: calc(100vh - 80px);
}

.csc-success-msg {
  color: green;
}

.csc-error-msg {
  color: red;
}