.sticky-top {
  position: sticky !important;
  top: 0;
  z-index: 9999999;
}

.arrow-fill-color {
  fill: white;
}

.subitems-card {
  background-color: #fff;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, .08);
  min-height: 9rem;
  left: 0;
  position: fixed;
  top: 0px;
  z-index: 1111;
  width: 100%;
  margin-top: 3rem !important;
  border-bottom: 2px solid #613c58;
}

.logout-height {
  height: 100%;
  width: 80px !important;
}

.selected-link {
  border-bottom: 2px solid white;
}

.css-maintenance-header {
  background-color: #6b6d72;
  color: white;
  width: 100%;
  height: 50px;
  position: sticky;
  z-index: 60000;
  top: 0px
}

/* .x-mobile-sidebar {
  position: absolute;
  width: 100%;
  width: 100vw;
  height: 100%;
  left: 0;
  right: 0;
  top: 3rem;
  bottom: 0;
  z-index: 9999;
} */


/* .bx--side-nav {
  z-index: 99999
} */
