.css-neo-row-h {
  height: 45px;
}

.css-domain-w {
  width: 150px;
  vertical-align: middle;
}

.css-scale-w {
  width: 125px;
}

.css-desc-w {
  width: 220px;
}