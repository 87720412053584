.css-sten-graph-h {
  height: 250px;
}

.css-sten-graph-personality-style-h {
  height: 140px;
}

.css-thinking-st-h {
  height: 360px;
}