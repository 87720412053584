.table-border th {
  border-right: 1px solid black;
  padding: 4px;
  vertical-align: middle;
}

.invalid-color {
  color: #da1e28;
}

.trait-color-1 {
  color: #fe0000;
}

.trait-color-2 {
  color: #0071c1;
}

.trait-color-3 {
  color: #00af50;
}

.trait-color-4 {
  color: #ffc000;
}

.trait-color-5 {
  color: #843c0c;
}

.trait-color-6 {
  color: #000000;
}

.trait-color-7 {
  color: #7030a0;
}

.trait-color-8 {
  color: #012060;
}

.trait-color-9 {
  color: #01b0f1;
}

.trait-color-10 {
  color: #c00000;
}

.trait-color-11 {
  color: #24879c;
}

.trait-color-12 {
  color: #92d14f;
}

.trait-color-13 {
  color: #7e6000;
}

.trait-color-14 {
  color: #ff00fe;
}

.trait-color-15 {
  color: #8397b0;
}

.trait-color-16 {
  color: #000000;
}

.css-color-w {
  color: white;
}

.css-color-w:hover {
  color: white !important;
  text-decoration: none !important;
}

.css-trait-top {
  top: 4px;
  position: relative;
}

.css-vertical-min-h {
  min-height: 600px;
}

.css-table-row-data {
  min-width: 95px;
  max-width: 95px;
  width: 95px;
}

.css-s-no-w {
  width: 42px;
  max-width: 42px;
  min-width: 42px;
}

.csc-radio-btn-m .bx--radio-button__appearance,
.css-h-d-input-t {
  margin-right: 0px;
}

.csc-shortlist-btn-m {
  margin-left: 75px;
}

.csc-date-inp-time .react-datepicker-wrapper,
.css-h-d-input-t {
  width: 60px !important
}

.csc-cand-card-fixed {
  position: fixed;
  z-index: 99;
  left: 0px;
  right: 0px;
  top: 80px;
}

.csc-cand-card-fixed-local {
  position: fixed;
  z-index: 99;
  left: 0px;
  right: 0px;
  top: 50px;
}

.cand-card-margin-top {
  margin-top: 135px !important;
}

.cand-list-table .bx--toolbar-search-container-active {
  width: 60% !important;
}

.assess-cand-card-margin-top {
  margin-top: 80px !important;
}