.dropdown .education.light> :first-child {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    font-size: 0.875rem;
    width: 100%;
    height: 2.5rem;
    border-radius: initial;
    color: #16233A;
    background-color: white;
    border: none;
    box-shadow: 0 1px 0 0 #525C6D;
    /* order: 2; */
    border-bottom: 1px solid transparent;
}

.dropdown .education.search {
    min-height: 48px;
    background: white;
}

.dropdown .education.search .css-1pahdxg-control {
    outline: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    top: 5px !important;
}

.dropdown .css-26l3qy-menu {
    z-index: 9999999 !important;
}

.dropdown .education.search>div:first-child {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    font-size: 0.875rem;
    width: 100%;
    height: 48px;
    border-radius: initial;
    color: #16233A;
    background-color: white;
    border: none;
    outline: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    top: 0px;
}

.dropdown .education.default> :first-child {
    font-family: "ibm-plex-sans", Helvetica Neue, Arial, sans-serif;
    font-size: 0.875rem;
    width: 100%;
    border-radius: initial;
    color: #16233A;
    background-color: #F3F4F5;
    outline: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 1px solid #8d8d8d !important;
    box-shadow: none !important;

}

.invalid {
    box-shadow: 0 2px 0 0 #C50E2E;
}

.dropdown-disabled {
    pointer-events: all !important;
}

.dropdown-disabled .css-1fhf3k1-control {
    cursor: not-allowed !important;
}