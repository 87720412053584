.css-table-min-h {
  height: 50px;
  vertical-align: middle;
  min-width: 200px;
  max-width: 200px;
}

.css-table-data-w-h {
  height: 50px;
  vertical-align: middle;
}

.overflow-scroll {
  overflow: scroll;
}

.w-85 {
  width: 85%;
}