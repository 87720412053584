

.css-tooltip-fill .bx--assistive-text, .css-tooltip-fill:hover .bx--assistive-text {
  color: white !important;
  max-width: 25rem;
}

.bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before {
  border-color: transparent transparent gray transparent;
}
