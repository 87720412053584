.xpa-error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-height: 100vh;
}
.xpa-error-content {
  background: #fff;
  border: 1px solid #eee;
  max-width: 600px;
}
.sentry-error-embed .form-submit .powered-by {
  display: none !important;
}
.sentry-error-embed .form-submit .btn {
  background-color: #5558AF !important;
  border-color: #5558AF !important;
}