.registraton-form {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.rf-wrap {
  padding: 20px 40px;
  max-width: 500px;
}
.rf-wrap .bx--form-item{
  margin: 5px 10px;
}
.rf-btn {
  flex: 1;
  margin: 34px 10px 0px;
}