.table-border-cca {
    border: 1px solid white;
    text-align: center;
    min-width: 50rem !important;
}

.table-border-cca tr, .table-border-cca th, .table-border-cca td {
    background-color: #393939 !important;
    color: white !important;
    height: 0 !important;
    font-size: small !important;
}

.table-border-cca th {
    border: 1px solid white;
    padding: 0.3rem !important;
    background-color: #585656 !important;
    font-weight: 600 !important;
}

.table-border-cca td {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    padding: 0.3rem !important;
    vertical-align: middle;
}