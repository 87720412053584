.scenario-date-input {
    width: 110px !important;
    height: 42px !important
  }

.expiry-date-input {
  width: 160px !important;
  height: 35px !important
}

.xpa-invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
}

.xpa-invalid-text {
  color: #da1e28;
  display: block;
  overflow: visible;
  max-height: 12.5rem;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.33333;
  letter-spacing: .32px;
  margin: 0.25rem 0 0;
}