.xpa-c-backrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #162759  !important;
}
.xpa-c-backrow .bx--link {
  cursor: pointer;
  font-size: 1rem;
  color: #162759  !important;
}
.xpa-c-backrow .xpa-c-backrow--item * {
  font-size: 1rem;
  margin-top: 0.05rem;
}

.backrow-name {
  cursor: pointer;
}

.backrow-name .bx--link {
  color: #162759  !important;
}

.xpa-color--gray03 {
  color: gray !important;
}