.bx--cc--chart-svg svg.layout-child {
  overflow: hidden !important;
}

.legend-box {
  border: 0.2px solid #dbd5d5;
  border-radius: 1px;
  min-width: 15px;
  max-width: 15px;
  min-height: 15px;
  max-height: 15px;
}

.tooltip-box {
  border: 1px solid white;
  width: 15px;
  height: 15px;
}

.tooltip-sc-box {
  border: 1px solid gray;
  width: 30px;
  height: 30px;
}

.tooltip-box-bg {
  background-color: white;
}