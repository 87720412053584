.chatGpt-search-main-div {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.expanding-textarea {
  resize: none;
  width: 100%;
  padding: 0.375rem 2.7rem 0.375rem 0.375rem;
  border-radius: 26px;
}

.expanding-textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.chatGpt-search-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.chatGpt-search-button:focus {
  outline: none;
}