.sgds-footer {
  z-index: 99999999999;
  width: 100%
}

.sgds-footer .footer-link-title {
  color: #fff
}

.sgds-footer a {
  color: #b8b8b8;
  font-size: 17px;
  padding-right: 12px;
  line-height: 20px
}

.sgds-footer a:hover {
  color: #fff
}

.sgds-footer a:last-child {
  padding-right: 0
}

.sgds-footer p {
  color: #b8b8b8;
  line-height: 25px;
  font-size: 1.0625rem
}

.sgds-footer p+p {
  margin-top: 15px
}

.sgds-footer ul {
  margin: 0
}

.sgds-footer ul li {
  display: inline-block;
  margin-top: 0
}

@media screen and (max-width:768px) {
  .sgds-footer ul li {
    display: block
  }
}

.sgds-footer ul li.icons {
  margin-right: 10px
}

.sgds-footer ul li a {
  color: #b8b8b8
}

.sgds-footer ul li a:hover {
  color: #fff
}

.sgds-footer ul li+li:not(.icons) {
  margin-left: 15px
}

@media screen and (max-width:768px) {
  .sgds-footer ul li+li:not(.icons) {
    margin-left: 0;
    margin-top: 15px
  }
}

.sgds-footer.sgds-footer-links li+li {
  margin-left: 10px
}

.sgds-footer .is-right-desktop-only {
  text-align: right;
  padding-top: 4px
}

@media screen and (max-width:769px) {
  .sgds-footer .is-right-desktop-only {
    text-align: left
  }
}

.sgds-footer>.bottom-section {
  padding: 1rem 2rem;
  background-color: #1a1a1a
}

.sgds-footer>.bottom-section .row {
  display: flex;
  align-items: center
}

@media screen and (max-width:769px) {
  .sgds-footer>.bottom-section .row {
    display: block
  }
}

.sgds-footer>.bottom-section .row .col {
  padding-top: .5rem
}

.sgds-footer>.bottom-section .row .col:last-child {
  padding-top: 0
}

.sgds-footer>.bottom-section p {
  font-size: 1.0625rem;
  margin-bottom: 0
}