.redirect-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
  text-align: center;
}

.redirect-box {
  background: white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.redirect-box p {
  font-size: 1rem;
  color: #555;
}

.redirect-box a {
  color: #007bff;
  text-decoration: none;
}

.redirect-box a:hover {
  text-decoration: underline;
}

.redirect-spinner {
  margin: 20px auto 0;
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
