.csc-scores-rank-w {
  width: 10px;
}

.csc-cand-name-w {
  width: 200px;
  min-width: 150px;
  max-width: 150px;
}

.csc-cand-name-w-psac {
  width: 225px;
  min-width: 225px;
  max-width: 225px;
}

.csc-cand-name-w-peer {
  width: 165px;
  min-width: 165px;
  max-width: 165px;
}

.csc-cand-name-exec-summ-w {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.csc-cand-name-f-sz {
  font-size: 12px !important;
}

.csc-traits-w {
  width: 105px;
  min-width: 105px;
  max-width: 105px;
}

.css-traits-border-b {
  border-bottom: 2px solid #84878C !important;
}

.css-table-row-h {
  height: 40px;
}

.csc-shaded-bg {
  background-color: #c4c6ca;
}

.csc-assessment-center-summary {
  line-height: 1.2;
  font-size: larger;
}

.csc-comments-circles {
  border: 1px solid black;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  background-color: black;
}