.csc-cand-notes-accr .bx--accordion__content {
  padding-left: 0px;
}

.csc-cand-notes-td-bg td {
  background: white !important;
}

.csc-cand-notes-css div, .csc-cand-notes-css p, .csc-cand-notes-css span, .csc-cand-notes-css ul, .csc-cand-notes-css li {
  background-color: white !important;
}