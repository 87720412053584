.schl-width {
  width: 44%;
}

.status-width {
  width: 19%;
}

.gba-height {
  height: 1000px;
  width: 100%;
}

.nivo-graph-height {
  height: 250px;
  width: 100%;
}

.content-padding .bx--accordion__heading {
  background-color: #f4f4ff!important;
}

.content-padding .bx--accordion__content {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.res-width {
  width: 63%;
}

.desc-font {
  font-size: large;
}

.scatter-plot-height {
  height: 450px;
  width: 100% !important;
}

.sat-score-wdth {
  width: 29%;
}

.thinking-style {
  height: 370px;
  width: 100%;
}

.personal-graph-height {
  height: 117px;
  width: 100%;
}

.gba-accor-content .bx--accordion__heading {
  background-color: #d3d3d3ba;
}

.gba-accor-content .bx--accordion__content {
  padding-right: 0px;
}

.batch-info-g-h {
  height: 450px;
  width: 100%;
}

.css-table-batch-info {
  border: 2px solid #84878C;
  text-align: center;
}

.css-table-batch-info .css-table-head {
  border-right: 1px solid #84878C;
  padding: 4px;
  border-top: 1px solid #84878C;
  vertical-align: bottom;
  font-size: small !important;
}

.css-metric-table-head {
  border-right: 1px solid #84878C;
  padding: 4px;
  vertical-align: bottom;
  font-size: small !important;
}

.css-total-f-sz {
  font-size: small !important;
  font-weight: bold;
}

.css-table-batch-info td {
  border-right: 1px solid #84878C;
  padding: 4px;
  border-top: 1px solid #84878C;
  vertical-align: bottom;
}

.css-batch-table-execercise-w {
  width: 100px;
  max-width: 100px;
}

@media (max-width: 780px) {
  .css-batch-table-execercise-w {
    width: 65px !important;
    max-width: 65px !important;
    overflow-wrap: break-word;
  }
}

@media (max-width: 780px) {
  .css-overall-excersises-w {
    width: 100% !important;
  }
}

.css-total-f-w {
  font-weight: bold;
}

.css-batch-metric-border {
  border-top: 2px solid #84878C !important;
}

.css-batch-metric-border-r {
  border-right: 2px solid #84878C !important;
}

@media (max-width: 780px) {
  .css-batch-metric-border-r {
    border-right: 2px solid #84878C !important;
    max-width: 100px;
    overflow-wrap: break-word;
  }
}

.align-mid {
  vertical-align: middle !important;
}

.css-overall-w {
  width: 200px;
}

.graphs-height {
  height: 350px;
  width: 100%;
}

.css-border-graph {
  border: 1px solid white;
  width: 200px;
  padding: 15px
}

.css-search-height {
  height: calc(100vh - 200px)
}

.css-total-bg {
  background-color: #ffff00a6;
}

.csc-pos--top-c {
  position: relative;
  top: 7px;
}

.xpa-cand-info--tabs .bx--tabs__nav {
  margin-left: 0px;
}


.xpa-c-tabs--vertical .bx--tabs__nav {
  flex-direction: column;
}

@media (max-width: 760px) {
  .css-statistics-zoom-auto {
    zoom: 0.5;
  }
}

.css-batch-info-head {
  height: 42px;
  vertical-align: middle;
}

.css-text-color {
  color: #162759 !important;
}

.css-top-insights {
  position: relative;
  top: 7px !important;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .css-h-d-input {
  height: 42px;
}

.css-gba-ques {
  line-height: 20px
}

.css-int-ques-line-h {
  line-height: 1.5;
}

.css-l-h {
  line-height: 1.3;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.css-table-row-min-h {
  height: 2.5rem !important;
}

.overflow-scroll {
  overflow: scroll;
}

.trait-min-width {
  min-width: 500px;
  max-width: 500px;
  width: 500px;
}

.xpa-psc-appr-w .bx--tabs__nav-link, .xpa-psc-appr-w .bx--tabs__nav-item {
  width: 100% !important;
}

.csc-psac-color--1, .csc-psac-color--2, .csc-psac-color--3, .csc-psac-color--4, .csc-psac-color--5, .csc-psac-color--6, .csc-psac-color--7 {
  background-color: #84878C;
}

.xpa-bg-color--gray {
  background-color: #d9d9d9;
}

.xpa-psac-title-fz {
  font-size: 14px !important;
}

.csc-scatter-plot-of {
  overflow-x: scroll !important;
  overflow-y: hidden;
}

.csc-f-dec-u {
  text-decoration: underline;
}